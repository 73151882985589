body {
  margin: 0;
  padding: 0;
  font-family: "Roboto Condensed", sans-serif;
}


/* Example: fonts.css */

@font-face {
  font-family: 'Roboto Condensed';
  src: url('./fonts/EnnVisions.otf') format('truetype');

}

