.page11-custom {
  /* margin-bottom: 90px; */
  background-color: #fff;
}

.profile-page11 {
  display: flex;
  max-width: 930px;
  margin: 0 auto;
  margin-bottom: 7rem;
}

.page-11.form-container {
  max-width: 1200px;
  margin-top: 20px;
  margin-bottom: 60px;
  font-family: "Roboto Condensed", sans-serif;
  box-shadow: 0px 0px 4px #00000029;
  padding: 0.4rem;
  border-radius: 2px;
  overflow-x: auto;
  width: 100%;
  color: #1b283f;
  overflow-y: scroll;
height: 70vh;
}

.page-11.form-container table {
  width: 100%;
}

.page-11.form-container table thead {
  background-color: #f2f2f7;
}

.page-11.form-container table thead th {
  height: 38px;
  color: #1b283f;
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
}

.page-11.form-container table .img-text-td {
  display: flex;
  width: 500px;
  min-width: 500px;
}

.page-11.form-container table .img-text-td img {
  width: 111px;
  border-radius: 5px;
  margin: 10px 10px 10px 0;
}

.page-11.form-container table .img-text-td .img-text-content-part {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.page-11.form-container table tbody tr {
  border-bottom: 1px solid lightgray;
}

.page-11.form-container table tbody td {
  padding-left: 10px;
  color: #1b283f;
}

.page-11.form-container table tbody td.buyer-td,
.page-11.form-container table tbody td.booked-td {
  vertical-align: top;
  padding-top: 10px;
  font-size: 18px;
}

.page-11.form-container table tbody td.buyer-td {
  color: #1c1c1e;
  width: 250px;
  min-width: 150px;
}

.page-11.form-container table tbody td.booked-td {
  color: #007aff;
  width: 150px;
  min-width: 150px;
  text-align: center;
  padding-top: 2.6rem;
}

.page-11.form-container table tbody td.price-td {
  font-size: 17px;
  width: 300px;
  min-width: 100px;
  /* font-weight: bold; */
}

.profile-section {
  max-width: 308px;
  width: 100%;
  margin-top: 20px;
  margin-left: 20px;
  font-family: "Roboto Condensed", sans-serif;
}

.profile-section .profile-info {
  padding: 10px 5px;
  box-shadow: 0px 0px 4px #00000029;
  margin-bottom: 200px;
}

.profile-section .profile-info .top-part {
  background-color: #f2f2f7;
  color: #1b283f;
  font-weight: bold;
  font-size: 16px;
  padding-left: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 2px;
}

.profile-section .profile-info .info-details {
  padding-top: 10px;
  border-bottom: 1px solid #00000029;
  padding-left: 5px;
}

.profile-section .profile-info .info-details .name {
  color: #1b283f;
  font-size: 16px;
}

.profile-section .profile-info .info-details .e-name {
  font-weight: 100;
}

.payment-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #efeff4;
}

table.user-table .img-text-td {
  width: 360px !important;
  min-width: 360px !important;
}

.checkout-page .profile-page11 {
  max-width: 767px;
}

/* .checkout-page .cross-img {
    width: 20px !important;
    position: absolute; 
    top: 10px; 
    right: 10px;
    cursor: pointer;
  } */

.checkout-page .profile-section .profile-info .info-details {
  display: flex;
  justify-content: space-between;
  border-bottom: none;
}

.checkout-page .profile-section .profile-info .info-details .name {
  font-size: 18px;
}

.checkout-page .profile-section .profile-info .info-details .e-name {
  font-weight: 400;
}

.checkout-page .checkout-btn {
  background-color: #d73829;
  color: #fff;
  border-radius: 2px;
  max-width: 257px;
  height: 48px;
  border: none;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.checkout-page .profile-section {
  max-width: 256px;
}

.page11-custom.checkout-page.checkout-variant-2 .profile-page11 {
  display: block;
}

/* .page11-custom.checkout-page.checkout-variant-2
    .profile-page11
    .page-11.form-container {
    margin-left: auto;
    margin-right: auto;
  }
  
  .page11-custom.checkout-page.checkout-variant-2 .page-11.form-container {
    max-width: 464px;
    margin-bottom: 20px;
  } */

.page11-custom.checkout-page.checkout-variant-2 .profile-section {
  max-width: 464px;
  margin-left: auto;
  margin-right: auto;
}

.page11-custom.checkout-page.checkout-variant-2
  .page-11.form-container
  table
  .img-text-td {
  width: 360px !important;
  min-width: 360px !important;
}

.page11-custom.checkout-page.checkout-variant-2 .checkout-btn.paynow {
  max-width: 90%;
}

.card-body {
  display: flex;
  /* gap: 1rem; */
  flex-direction: column;
  flex-wrap: wrap;
  padding: 3px 20px !important;
}
.card-img {
  height: 220px;
}
@media (max-width: 991px) {
  .profile-page11 {
    display: block;
  }
  

  .page-11.form-container {
    max-width: 100%;
  }

  .profile-section {
    max-width: 100% !important;
    margin-left: 0px;
  }

  .page-11.form-container {
    margin-bottom: 20px;
  }
}

@media (max-width: 890px) {
  .page11-custom {
    margin: 0 15px;
  }
}

@media (max-width: 767px) {
  .profile-page11 {
    margin-bottom: 70px;
  }

}

@media (min-width: 992px) {
  .page11-custom.checkout-page.checkout-variant-2 .check-btn {
    margin-right: 35px;
  }
}
@media screen and (width<768px) {
  .page-11.form-container table tbody td.price-td {
    font-size: 18px;
    width: 300px !important;
    min-width: 100px;
    font-weight: bold;
  }  
}